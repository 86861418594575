import jQuery from 'jquery';

window.jQuery = jQuery;
const $ = jQuery;


$(window).on('scroll', function() {
	const position = 20;
	const scrollValue = $(this).scrollTop();

	const btn = $('.backtop__item');

	if(scrollValue > position) {
		btn.addClass('reverse');
		btn.attr('href', '#header');
	}

	if(scrollValue <= position) {
		btn.removeClass('reverse');
		btn.attr('href', '#home-about');
	}
});

// スムーズスクロール
$(function() {
	$('a[href^="#"]').click(function(){
		var speed = 500;
		var href= $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top;
		$("html, body").animate({scrollTop:position}, speed, "swing");
		return false;
	});
});

// ハンバーガーメニュー
$(function() {
	$('.hamburger').on('click', function() {
		$(this).toggleClass('hamburger--active');
		$('body').toggleClass('js-body');
		$('.gnav__wrap').toggleClass('gnav__wrap--active');
	});
});